<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <div class="logo">
      <img src="@/assets/img/logo.png" alt="">
    </div>
    <social-links/>
    <div
      v-for="({ title, img, items, special, notes }, index) in data" :key="index"
    >
      <header-image :src="img" :title="title"/>
      <menu-list
        :items="items"
        :special="special"
        :notes="notes"
      />
    </div>
  </div>
</template>

<script>
import HeaderImage from './components/HeaderImage'
import MenuList from './components/MenuList'
import SocialLinks from './components/SocialLinks'
import fetchData from './data'

export default {
  name: 'App',
  components: {
    HeaderImage,
    MenuList,
    SocialLinks
  },
  data () {
    return {
      data: fetchData
    }
  },
  created () {
    // this.fetch()
    // console.log('env', process.env)
  },
  methods: {
    async fetch () {
      this.data = await fetch(`${process.env.VUE_APP_BASE_URL}/data.json`).then(res => res.json()).catch(err => console.log(err))
    }
  }
}
</script>

<style lang="scss">
.logo {
  width: 150px;
  margin: 0 auto 20px;
  img {
    width: 100%;
    display: block;
  }
}
</style>
