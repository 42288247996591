var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"menu box-shadow mb-section"},[_c('table',{staticClass:"products"},_vm._l((_vm.items),function(ref,index){
var name = ref.name;
var price = ref.price;
var details = ref.details;
return _c('tr',{key:index},[_c('td',{staticClass:"item"},[_vm._v(" "+_vm._s(name)+" "),(details)?[_c('br'),_c('span',{staticClass:"details",domProps:{"innerHTML":_vm._s(details)}})]:_vm._e()],2),_c('td',{staticClass:"price"},[_vm._v("$ "+_vm._s(price)+".00")])])}),0),(_vm.special.length)?[_c('div',{staticClass:"divider"}),_c('table',{staticClass:"products"},_vm._l((_vm.special),function(ref,index){
var name = ref.name;
var price = ref.price;
var details = ref.details;
return _c('tr',{key:index},[_c('td',{staticClass:"item"},[_vm._v(" "+_vm._s(name)+" "),(details)?[_c('br'),_c('span',{staticClass:"details",domProps:{"innerHTML":_vm._s(details)}})]:_vm._e()],2),_c('td',{staticClass:"price"},[_vm._v("$ "+_vm._s(price)+".00")])])}),0)]:_vm._e(),(_vm.notes.length)?[_c('div',{staticClass:"divider"}),_vm._l((_vm.notes),function(note,index){return _c('p',{key:index,staticClass:"note"},[_vm._v(_vm._s(note))])})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }