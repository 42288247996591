export default [
  {
    title: 'TACOS',
    img: './img/tacos.jpg',
    items: [
      {
        name: 'Tradicionales',
        price: 45,
        details: 'Ya los conoces, pero estos estan con madre'
      },
      {
        name: 'Supremos',
        price: 50,
        details: 'Dicen que si adivinas qué los hace supremo, te regalan un poco +'
      },
      {
        name: 'De Camarón',
        price: 68,
        details: 'Con salsa suprema la mera mera'
      },
      {
        name: 'Al Capón',
        price: 45,
        details: 'Si lo que te gusta es dulce, esto es para ti'
      },
      {
        name: 'Cochinita',
        price: 52,
        details: 'Si quieres probar la cochinita como nunca antes, estos son los ideales'
      },
      {
        name: 'Chicharón Prensado',
        price: 52,
        details: 'Bañados en nuestra salsa Gemma'
      },
      {
        name: 'Arrachera',
        price: 74
        // details: 'Bañados en nuestra salsa Gemma'
      },
      {
        name: 'Taco extra Tradicional',
        price: 16
      },
      {
        name: 'Taco extra Supremo',
        price: 18
      },
      {
        name: 'Taco extra Camarón',
        price: 25
      },
      {
        name: 'Taco extra Cochinita',
        price: 18
      },
      {
        name: 'Taco extra Capón',
        price: 16
      },
      {
        name: 'Taco extra Chicharrón Prensado',
        price: 18
      },
      {
        name: 'Taco extra Arrachera',
        price: 26
      }
    ],
    notes: [
      'Todas nuestras ordenes son de 3 tacos'
    ]
  },
  {
    title: 'TORTAS',
    img: './img/tortas.jpg',
    items: [
      {
        name: 'Tradicional',
        price: 47
      },
      {
        name: 'Suprema',
        price: 52
      },
      {
        name: 'De Camarón',
        price: 69
      },
      {
        name: 'Al Capón',
        price: 47
      },
      {
        name: 'Cochinita',
        price: 54
      },
      {
        name: 'La Bañadita',
        price: 54,
        details: 'Chicharón prensado bañado en nuestra salsa Gemma'
      },
      {
        name: 'Arrachera',
        price: 78
      }
    ]
  },
  {
    title: 'TOSTADAS',
    img: './img/tostadas.jpg',
    items: [
      {
        name: 'Carne Apache',
        price: 34
      },
      {
        name: 'Tiritas de pescado',
        price: 34
      },
      {
        name: 'Camarón',
        price: 40
      },
      {
        name: 'Aguachile de Camarón',
        price: 45
      }
    ]
  },
  {
    title: 'BEBIDAS',
    img: './img/bebidas.jpg',
    items: [
      {
        name: 'Agua sabor',
        price: 22,
        details: 'Pepino con limón y horchata <br /> Jamaica con hierbabuena (solo sucursal acueducto)'
      },
      {
        name: 'Refresco',
        price: 24
      },
      {
        name: 'Cerveza',
        details: 'Corona / Victoria',
        price: 30
      },
      {
        name: 'Cubeta (6 pzas)',
        details: 'Corona / Victoria',
        price: 150
      },
      {
        name: 'Michelada 1/2',
        price: 50
      },
      {
        name: 'Michelada Litro',
        price: 90
      },
      {
        name: 'Vaso Preparado',
        price: 16
      },
      {
        name: 'Caguama',
        details: 'Corona / Victoria',
        price: 74
      },
      {
        name: 'Shot de mezcal Real Matlatl Espadin 39*',
        price: 55
      },
      {
        name: 'Bot. de mezcal Real Matlatl Espadin 39*',
        price: 750
      },
      {
        name: 'Mezcal Cura Todo',
        price: 45
      },
      {
        name: 'Mezcalada 1/2',
        price: 58
      },
      {
        name: 'Mezcalada 1 lt',
        price: 110
      }
    ]
    // special: [
    //   {
    //     name: 'Chela + Mezcal',
    //     price: 55
    //   }
    // ]
  },
  {
    title: 'PAQUETES',
    img: './img/paquetes.jpg',
    items: [
      {
        name: 'Tradicional',
        price: 75,
        details: '1/2 TORTA TRADICIONAL <br>+ 2 TACOS TRADICIONALES <br>+ AGUA DE SABOR'
      },
      {
        name: 'Supremo',
        price: 80,
        details: '1 TORTA SUPREMA <br>+ 1 TACO SUPREMOS <br>+ 2 AGUAS DE SABOR'
      },
      {
        name: 'Pareja Suprema',
        price: 160,
        details: '1 TORTA TRADICIONAL <br>+ 2 ORDENES TACOS SUPREMOS <br>+ AGUA DE SABOR'
      }
    ]
  },
  {
    title: 'POSTRES',
    img: './img/postres.jpg',
    items: [
      {
        name: 'Pay de queso',
        price: 45
      }
    ]
  }
  // {
  //   title: 'HAMBURGUESAS <br><small class="title-note">*De 5:30pm a 11:00pm<small>',
  //   img: './img/hamburguesas.jpg',
  //   items: [
  //     {
  //       name: 'Sencilla',
  //       price: 55,
  //       details: 'Pan  130 GR de Carne de res, queso amarillo, lechuga sangría, Jitomate, cebolla morada, pepinillos en juliana y papa crunch.  '
  //     },
  //     {
  //       name: 'Especial',
  //       price: 60,
  //       details: 'Pan 130 GR de carne de res, lechuga sangría, jitomate, cebolla morada, pepinillos en juliana, papa crunch, queso asadero, jamón'
  //     },
  //     {
  //       name: 'Mermelada Tocino',
  //       price: 65,
  //       details: 'Pan, Carne de res 130 gr, lechuga sangría, jitomate, cebolla morada, pepinillos en juliana, papa frita y mermelada de tocino'
  //     },
  //     {
  //       name: 'Jalapeño',
  //       price: 65,
  //       details: 'Pan, 130 GR de Res, Lechuga sangría, jitomate, cebolla morada, pepinillos en juliana, papa crunch, queso manchego, Jalapeño relleno de queso asadero, adereso chipotle'
  //     },
  //     {
  //       name: 'Troyan Burguer',
  //       price: 70,
  //       details: '130 GR de Carne de res, lechuga sangria, jitomate, tocino, capitan bbq, aros de cebolla, queso amarillo'
  //     }
  //   ]
  // },
  // {
  //   title: 'BONELESS <br><small class="title-note">*De 5:30pm a 11:00pm<small>',
  //   img: './img/boneless.jpg',
  //   items: [
  //     {
  //       name: 'Sabores',
  //       price: 90,
  //       details: `
  //         BBQ Mango Habanero <br>
  //         BBQ Mango <br>
  //         Sweet Chipotle <br>
  //         BBQ
  //       `
  //     },
  //     {
  //       name: 'Pa\' que compartas',
  //       price: 150,
  //       details: `
  //         Papas <br>
  //         Boneless <br>
  //         Aros de cebolla
  //       `
  //     }
  //   ]
  // },
  // {
  //   title: 'PAPAS <br><small class="title-note">*De 5:30pm a 11:00pm<small>',
  //   img: './img/papas.jpg',
  //   items: [
  //     {
  //       name: 'Papas Cheese, Queso y Tocino',
  //       price: 60
  //     },
  //     {
  //       name: 'Papas a la francesa',
  //       price: 45
  //     }
  //   ]
  // }
]
