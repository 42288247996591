<template>
  <section class="menu box-shadow mb-section">
    <table class="products">
      <tr
        v-for="({ name, price, details }, index) in items" :key="index"
      >
        <td class="item">
          {{ name }}
          <template v-if="details">
            <br>
            <span v-html="details" class="details"></span>
          </template>
        </td>
        <td class="price">$ {{ price }}.00</td>
      </tr>
    </table>
    <template v-if="special.length">
      <div class="divider"></div>
      <table class="products">
        <tr
          v-for="({ name, price, details }, index) in special" :key="index"
        >
          <td class="item">
            {{ name }}
            <template v-if="details">
              <br>
              <span v-html="details" class="details"></span>
            </template>
          </td>
          <td class="price">$ {{ price }}.00</td>
        </tr>
      </table>
    </template>
    <template v-if="notes.length">
      <div class="divider"></div>
      <p v-for="(note, index) in notes" :key="index" class="note">{{ note }}</p>
    </template>
  </section>
</template>

<script>
export default {
  name: 'ProductList',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    special: {
      type: Array,
      default: () => []
    },
    notes: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped lang="scss">
.menu {
  background: url('~@/assets/img/fondo.jpg')repeat-y center top;
  // background: #FFF;
  background-size: 100%;
  padding: 10px 15px;
  max-width: 1250px;
  table {
    &.products {
      width: 100%;
      td {
        padding: 3px 0;
        color: #f2f2f2;
        vertical-align: top;
      }
      td.item {
        width: 70%;
        text-align: left;
        padding-bottom: 0.5em;
        // text-transform: uppercase;
      }
      td.price {
        text-align: right;
        // font-weight: 300;
        // color: #F8D700;
      }
    }
    .details {
      font-weight: 300;
      font-size: 0.85rem;
      // text-transform: none;
    }
  }
}
.divider {
  width: 90%;
  height: 2px;
  display: block;
  margin: 0.5em auto;
  // background: rgba(255,255,255,0.7);
  border-top: 1px dashed rgba(255,255,255,0.7);
}
.note {
  color: #F8D700;
  font-size: 0.85em;
  &::before {
    content: "* "
  }
}
</style>
